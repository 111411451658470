<template>
  <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="modal.form" v-bind="modalFormLayout">
      <a-form-item label="项目">
        <a-select
          class="form-control lg"
          v-decorator="['projectCode', { rules: [{ required: true, message: '请选择' }] }]"
          placeholder="请选择"
          allowClear
          showSearch
          :options="projectList"
          :filterOption="filterByPY"
          @change="onSelectProject"
        ></a-select>
      </a-form-item>
      <a-form-item label="班组">
        <a-select
          class="form-control lg test"
          v-decorator="['leaderId', { rules: [{ required: true, message: '请选择' }] }]"
          placeholder="请选择"
          allowClear
          showSearch
          :filterOption="filterByPY"
          :options="leaderList"
        ></a-select>
      </a-form-item>
      <a-form-item label="核销方式">
        <a-radio-group v-model="type">
          <a-radio :value="0">按日期核销</a-radio>
          <a-radio :value="1">按天数核销</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="日期" v-if="type === 0">
        <a-range-picker v-decorator="['date', { rules: [{ required: true, message: '请选择' }] }]" />
      </a-form-item>
      <template v-if="type === 1">
        <a-form-item label="月份">
          <a-month-picker
            v-decorator="['yearMonth', { rules: [{ required: true, message: '请选择' }] }]"
          ></a-month-picker>
        </a-form-item>
        <a-form-item label="核销天数">
          <a-input-number
            placeholder="请输入"
            :min="1"
            :precision="0"
            v-decorator="['days', { rules: [{ required: true, message: '请输入' }] }]"
          ></a-input-number>
        </a-form-item>
      </template>

      <a-form-item label="来源说明">
        <a-input
          v-decorator="[
            'source',
            {
              rules: [{ required: true, message: '请输入来源说明' }],
            },
          ]"
          placeholder="请输入"
        ></a-input>
      </a-form-item>
      <a-form-item label="流程id">
        <a-input
          v-decorator="[
            'requestId',
            {
              rules: [{ required: true, message: '请输入流程id' }],
            },
          ]"
          placeholder="请输入"
        ></a-input>
      </a-form-item>
      <a-form-item label="身份证号">
        <a-textarea
          v-decorator="[
            'cardIds',
            {
              rules: [
                { required: true, message: '请输入身份证号码' },
                {
                  validator: groupIdCard,
                },
              ],
            },
          ]"
          placeholder="多个身份证号使用,隔开"
        ></a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { toolMixin } from '@/utils/mixin'
import { getProjectList, getLeaderList } from '@/service/getData'
import { groupIdCard } from '@/utils/antd'
//           pattern: /^((\d{15}|\d{18}|\d{17}(\d|X|x))(,)?)+$/,
export default {
  mixins: [toolMixin],
  data() {
    return {
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '考勤核销',
        data: {},
      },
      projectList: [],
      leaderList: [],
      type: 0,
    }
  },
  methods: {
    groupIdCard,
    async start() {
      this.resetModal()
      this.projectList = await getProjectList()
      this.modal.visible = true
      return new Promise((resolve, reject) => {
        this.$once('end', function (state, result) {
          if (state === 'ok') {
            resolve(result)
          }
          if (state === 'cancel') {
            reject(new Error('E_CANCEL'))
          }
          this.modal.visible = false
        })
      })
    },
    async handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          let params = this.formatParams(values, (key, value) => {
            if (key === 'yearMonth') {
              return { key, value: value.format('YYYY-MM') }
            }
          })
          params = this.formatTimeRangeParams(params, 'date', {
            format: 'YYYY-MM-DD',
          })
          try {
            await this.$axios.post('/attendance/web/attendance/day/attendance/lock', {
              ...params,
              type: 4,
              cardIds: params.cardIds.replace(/\s/g, '').split(','),
            })
            this.$emit('end', 'ok')
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    async onSelectProject(projectCode) {
      this.modal.form.setFieldsValue({ leaderId: undefined })
      this.leaderList = projectCode ? await getLeaderList(projectCode) : []
    },
    handleCancel() {
      this.$emit('end', 'cancel')
    },
  },
}
</script>
