<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="项目">
              <a-select
                class="form-control lg"
                v-decorator="['projectCode']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :filterOption="filterByPY"
                @change="onSelectProject"
              ></a-select>
            </a-form-item>
            <a-form-item label="班组">
              <a-select
                class="form-control lg test"
                v-decorator="['leaderId']"
                placeholder="请选择"
                allowClear
                showSearch
                :filterOption="filterByPY"
                :options="leaderList"
              ></a-select>
            </a-form-item>
            <a-form-item label="身份证号">
              <a-input class="form-control" v-decorator="['cardId']" placeholder="请输入身份证号" allowClear></a-input>
            </a-form-item>
            <a-form-item label="考勤月份">
              <a-month-picker
                class="form-control"
                v-decorator="['attendanceMonth']"
                placeholder="选择考勤月份"
              ></a-month-picker>
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button type="primary" @click="writeOff">核销考勤</a-button>
          <a-button type="primary" @click="recount">重新汇总</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <WriteOff ref="WriteOff"></WriteOff>
    <Recount ref="Recount"></Recount>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { getProjectList, getLeaderList } from '@/service/getData'
import WriteOff from './modules/WriteOff'
import Recount from './modules/Recount'
export default {
  components: {
    WriteOff,
    Recount,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '项目名称',
        width: '15%',
        align: 'center',
        dataIndex: 'projectName',
      },
      {
        title: '项目编码',
        width: '6%',
        align: 'center',
        dataIndex: 'projectCode',
      },
      {
        title: '班组',
        width: '6%',
        align: 'center',
        dataIndex: 'leaderName',
      },
      {
        title: '名称',
        width: '6%',
        align: 'center',
        dataIndex: 'name',
      },

      {
        title: '身份证号码',
        width: '15%',
        align: 'center',
        dataIndex: 'cardId',
      },

      {
        title: '考勤月份',
        width: '6%',
        align: 'center',
        dataIndex: 'attendanceMonth',
      },

      {
        title: '考勤天数',
        width: '6%',
        align: 'center',
        dataIndex: 'attendanceDays',
      },
      {
        title: '累计小时数',
        width: '6%',
        align: 'center',
        dataIndex: 'totalHours',
      },

      {
        title: '锁定天数',
        width: '6%',
        align: 'center',
        dataIndex: 'lockDays',
      },
      {
        title: '异常调整天数',
        width: '6%',
        align: 'center',
        dataIndex: 'exceptionAdjustDays',
      },
      {
        title: '剩余可用天数',
        width: '6%',
        align: 'center',
        dataIndex: 'residueDays',
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },

      projectList: [],
      leaderList: [],
    }
  },
  computed: {
    typesMap() {
      return {
        type: [
          { value: 0, label: '正常考勤' },
          { value: 1, label: '加班考勤' },
        ],
      }
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const params = this.getPageParams(_pageNo, _pageSize)
        const res = await this.$axios.post('/attendance/web/attendance/month/list', {
          ...params,
          attendanceMonth: params.attendanceMonth ? params.attendanceMonth.format('YYYY-MM') : '',
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async onSelectProject(projectCode) {
      this.form.setFieldsValue({ leaderId: undefined })
      this.leaderList = projectCode ? await getLeaderList(projectCode) : []
    },
    async writeOff() {
      await this.$refs.WriteOff.start()
      this.$message.success('操作成功')
      this.doQuery()
    },
    async recount() {
      await this.$refs.Recount.start()
      this.$message.success('操作成功')
      this.doQuery()
    },
  },
  async mounted() {
    this.projectList = await getProjectList()
  },
}
</script>

<style lang="less" scoped></style>
