<template>
  <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="modal.form" v-bind="modalFormLayout">
      <a-form-item label="项目">
        <a-select
          class="form-control lg"
          v-decorator="['projectCode', { rules: [{ required: true, message: '请选择' }] }]"
          placeholder="请选择"
          allowClear
          showSearch
          :options="projectList"
          :filterOption="filterByPY"
        ></a-select>
      </a-form-item>
      <a-form-item label="身份证号">
        <a-textarea
          v-decorator="[
            'cardId',
            {
              rules: [
                { required: true, message: '请输入' },
                {
                  validator: groupIdCard,
                },
              ],
            },
          ]"
          placeholder="多个身份证号使用,隔开"
        ></a-textarea>
      </a-form-item>
      <a-form-item label="考勤月份">
        <a-month-picker
          class="form-control"
          v-decorator="[
            'attendanceMonth',
            {
              rules: [{ required: true, message: '请选择' }],
            },
          ]"
          placeholder="请选择考勤月份"
        ></a-month-picker>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { toolMixin } from '@/utils/mixin'
import { getProjectList } from '@/service/getData'
import { groupIdCard } from '@/utils/antd'

export default {
  mixins: [toolMixin],
  data() {
    return {
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '重新汇总',
        data: {},
      },
      projectList: [],
      leaderList: [],
    }
  },
  methods: {
    groupIdCard,
    async start() {
      this.resetModal()
      this.projectList = await getProjectList()
      this.modal.visible = true
      return new Promise((resolve, reject) => {
        this.$once('end', function (state, result) {
          if (state === 'ok') {
            resolve(result)
          }
          if (state === 'cancel') {
            reject(new Error('E_CANCEL'))
          }
          this.modal.visible = false
        })
      })
    },
    async handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            await this.$axios.post('/attendance/web/attendance/month/again/sum', {
              ...params,
              attendanceMonth: params.attendanceMonth.format('YYYY-MM'),
              cardId: params.cardId.replace(/\s/g, '').split(','),
            })
            this.$emit('end', 'ok')
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    handleCancel() {
      this.$emit('end', 'cancel')
    },
  },
}
</script>
